<template>
    <div class="PointWriteOff" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="机构组名称">
                            <el-select placeholder="请选择" v-model="form.deptGroupCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="bd in meta.deptGroup"
                                    :key="bd.code"
                                    :label="bd.name"
                                    :value="bd.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable :clearable="true">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptList"
                                    :key="dept.code"
                                    :label="dept.name"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="会员">
                            <el-input placeholder="会员名称/手机号" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="收银员">
                            <el-select v-model="form.staffCode" filterable :clearable="true">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="g in meta.staffList"
                                    :key="g.code"
                                    :value="g.code"
                                    :label="g.realName"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="开始日期">
                            <el-date-picker
                                type="date"
                                v-model="form.startTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结束日期">
                            <el-date-picker
                                v-model="form.endTime"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.member.point.writeOff.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.member.point.writeOff.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column
                    label="机构组名称"
                    width="140"
                    prop="deptGroupName"
                    v-if="showColumn('deptGroupName')"
                />
                <el-table-column label="机构名称" width="140" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="会员昵称" width="140" prop="memberName" v-if="showColumn('memberName')" />
                <el-table-column label="会员手机号" width="140" prop="memberMobile" v-if="showColumn('memberMobile')" />
                <el-table-column label="核销时间" width="150" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column label="核销积分" width="80" prop="point" v-if="showColumn('point')" />
                <el-table-column label="收银员" width="80" prop="staffName" v-if="showColumn('staffName')" />
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';

export default {
    name: 'PointWriteOff',
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                search: '',
                deptGroupCode: '',
                deptCode: '',
                staffCode: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            meta: {
                deptGroup: [],
                deptList: [],
                staffList: [],
            },
            loading: false,
            tableData: [],
            url: {
                queryDeptByGroup: '/system/dept/deptTree',
                page: '/memberPointChange/memberPointChangePage',
            },
        };
    },
    mounted() {
        this.inits();
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        inits() {
            const _this = this;
            UrlUtils.DeptGroup(_this, (rst) => {
                _this.meta.deptGroup = rst;
            });
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + '', (rst) => {
                _this.meta.deptList = rst;
            });
            //查询所有管理或所属机构的员工列表
            this.$efApi.staffApi.allRelatedStaff().then((rst = []) => {
                const temStaffCodes = [];
                this.meta.staffList = rst.filter(
                    //过滤出业务人员
                    (e) => {
                        if (e.type === 1 && !temStaffCodes.includes(e.code)) {
                            temStaffCodes.push(e.code);
                            return true;
                        } else {
                            return false;
                        }
                    }
                );
            });
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            UrlUtils.Export(this, '积分核销', '/memberPointChange/memberPointChangeExport', this.form, []);
        },
    },
};
</script>

<style scoped>
.PointGoods .el-form-item {
    margin-bottom: 0;
}
</style>
